import React, {
  useState, useEffect, useCallback, Fragment, isValidElement,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Container,
  Badge,
  Button,
  Modal,
  ButtonGroup,
  Spinner,
  Accordion,
  Table,
} from 'react-bootstrap';
import {
  FaPlus, FaCopy, FaTrash, FaPen, FaCalculator,
} from 'react-icons/fa';
import Api from '../../../services/api';
import ScenarioForm from './ScenarioForm';
import Scenario from './Scenario';

import { TextField, DropDown } from '../../common/components';
import './Scenarios.scss';
import TransportationForm from '../../CalculationsForms/TransportationForm';
import ProductForm from '../../CalculationsForms/ProductForm';

const emptyProjectScenario = {
  name: '',
  description: '',
  id: -1,
  status: '',
  projectType: '',
  locationGeo: '',
  locationName: '',
  locationType: '',
  projectManager: '',
  dates: '',
  elements: [{
    name: '',
    description: '',
    code: '',
    amount: 0,
    unit: {
      id: 0,
      name: 'string',
      symbol: 'string',
      abbreviation: 'string',
    },
    totalEmission: 0,
  }],
};

export default ({ projectId }) => {
  const { t } = useTranslation();

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({});
  const [showProjectScenarioDialog, setShowProjectScenarioDialog] = useState(false);
  const [showNoGroupError, setShowNoGroupError] = useState(false);
  const [showEditGroupsDialog, setShowEditGroupsDialog] = useState(false);
  const [projectScenario, setProjectScenario] = useState({ ...emptyProjectScenario, projectId });
  const [scenarios, setScenarios] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [selectedScenario, setSelectedScenario] = useState({ id: null });
  const [scenarioError, setScenarioError] = useState(null);

  const getProject = useCallback(async () => {
    setProject(await Api().projects(projectId).get());
  }, [projectId]);

  const getScenarios = useCallback(async () => {
    const response = await Api().scenarios().get({ projectId });
    if (response !== undefined) {
      if(response.length > 0){
        setSelectedScenario(response[0]);
      }      
      setScenarios(response);
      setLastUpdate(new Date());
    }
  }, [projectId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching data starts
      await getProject();
      await getScenarios();
      setLoading(false); // Set loading to false after both project and scenarios data have been fetched
    };

    if (!initialized) {
      fetchData();
      setInitialized(true);
    }
  }, [initialized, getProject, getScenarios]);

  useEffect(()=>{
    return ()=>{
      setScenarioError(null)
    }
  },[projectScenario])

  const handleCreateScenario = async () => {
    projectScenario.projectId = projectId;
    if (projectScenario.id <= 0) {
      if(projectScenario.name?.trim().length > 0){
        const scenario = await Api().scenarios().post(projectScenario);
        setProjectScenario(scenario);
        setShowProjectScenarioDialog(false);
        await getScenarios();
      }else{
        setScenarioError('Please enter scenario name');
      }
    } else {
      if(projectScenario.name?.trim().length > 0){
        projectScenario.projectId = projectId;
        const scenario = await Api().scenarios(projectScenario.id).update(projectScenario);
        setProjectScenario(scenario);
        setShowProjectScenarioDialog(false);
        await getScenarios();
      }else{
        setScenarioError('Please enter updated scenario name');
      }
    }    
  };

  // Using useEffect to reset the ScenarioForm. Seems to persist other ways.
  useEffect(() => {
    if (showProjectScenarioDialog) {
      setProjectScenario({
        name: '',
        description: '',
        id: -1,
        status: '',
        projectType: '',
        locationGeo: '',
        locationName: '',
        locationType: '',
        projectManager: '',
        dates: '',
        elements: [{
          name: '',
          description: '',
          code: '',
          amount: 0,
          unit: {
            id: 0,
            name: 'string',
            symbol: 'string',
            abbreviation: 'string',
          },
          totalEmission: 0,
        }],
      });
    }
  }, [showProjectScenarioDialog]);

  const [projectGroups, setProjectGroups] = useState([]);

  const updateProjectGroups = () => {
    if (project.groups === undefined) {
      return;
    }
    // update project groups
    setProjectGroups(project.groups.filter((g) => g.parentId === null || g.parentId === g.id).map((g) => {
      g.children = project.groups.filter((pg) => pg.parentId === g.id).map((pg) => {
        // Set parent name for listings
        pg.parentName = g.name;
        pg.children = [];
        return pg;
      });
      return g;
    }));
  };
  useEffect(() => {
    updateProjectGroups();
  }, [project]);

  /**
     * Copies group and its children. May cause two REST queries.
     *
     * @param {group to be cloned (including children)} toBeCloned
     */
  const handleAddGroup = (toBeCloned) => async (event) => {
    const createGroup = (g) => ({
      name: g.name, parentId: g.parentId, description: g.description, code: g.code === undefined ? null : g.code,
    });

    project.groups = [...project.groups, createGroup(toBeCloned)];
    const existingGroups = project.groups.map((g) => g.id);
    let updatedProject = await Api().projects(projectId).update(project);

    const parent = updatedProject.groups.find((g) => !existingGroups.includes(g.id));
    if (parent !== undefined && toBeCloned.children.length > 0) {
      updatedProject.groups = [...updatedProject.groups, ...toBeCloned.children.map((cg) => createGroup({ ...cg, parentId: parent.id }))];
      updatedProject = await Api().projects(projectId).update(updatedProject);
    }
    setProject(updatedProject);
    /* setProjectGroups(groups => {
            groups = [...groups, createGroup(toBeCloned), ...toBeCloned.children.map(cg => createGroup({ ...cg, parentId: parent.id }))]
            return groups
        }) */
  };

  const handleRemoveGroup = (toBeRemoved) => async (event) => {
    if (toBeRemoved.children === undefined) {
      toBeRemoved.children = [];
    }
    const removedIds = [toBeRemoved.id, ...toBeRemoved.children.map((cg) => cg.id)];
    project.groups = project.groups.filter((pg) => !removedIds.includes(pg.id));

    const updatedProject = await Api().projects(projectId).update(project);
    setProject(updatedProject);
  };

  const handleUpdateGroup = (groupId) => (data) => (event) => {
    setProject((current) => {
      const idx = current.groups.findIndex((g) => g.id === groupId);
      if (idx >= 0) {
        current.groups.splice(idx, 1, {
          ...current.groups[idx],
          name: data.name,
          code: data.code,
          parentId: (data.parentId === 0 || data.parentId === data.id || data.parentId === '') ? null : data.parentId,
          description: data.description,
        });
      }
      // return { ...current }
      return current;
    });
    updateProjectGroups();
  };

  const handleSaveProject = async (e) => {
    setProject(await Api().projects(projectId).update(project));
    setShowEditGroupsDialog(false);
  };

  return (
    <div>
      <Container fluid className="my-2">
        <Modal show={showEditGroupsDialog} onHide={() => { setShowEditGroupsDialog(false); }} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>
              {' '}
              {t('scenario.Edit groups')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <thead>
                <tr>
                  <th>{t('common.Code')}</th>
                  <th>{t('import.Parent group')}</th>
                  <th>{t('common.Name')}</th>
                  <th>{t('common.Description')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {projectGroups.sort((a, b) => (a.code < b.code ? -1 : 1)).map((g) => (
                  <Fragment key={g.id}>
                    <tr>
                      <td><TextField size="sm" object={g} prop="code" onChange={handleUpdateGroup(g.id)} /></td>
                      <td><DropDown size="sm" object={g} prop="parentId" options={projectGroups.filter((pg) => pg.id !== g.id)} optiontext="{code} {name}" optionvalue="id" onChange={handleUpdateGroup(g.id)} /></td>
                      <td><TextField size="sm" object={g} prop="name" onChange={handleUpdateGroup(g.id)} /></td>
                      <td><TextField size="sm" object={g} prop="description" onChange={handleUpdateGroup(g.id)} /></td>
                      <td>
                        <ButtonGroup>
                          <Button variant="outline-danger" size="sm" onClick={handleRemoveGroup(g)}><FaTrash /></Button>
                          <Button variant="outline-primary" size="sm" onClick={handleAddGroup(g)}><FaCopy /></Button>
                        </ButtonGroup>
                      </td>
                    </tr>

                    {g.children.map((gc) => (
                      <tr key={gc.id}>
                        <td style={{ paddingLeft: '2em' }}><TextField size="sm" object={gc} prop="code" onChange={handleUpdateGroup(gc.id)} /></td>
                        <td><DropDown size="sm" object={gc} prop="parentId" options={projectGroups} optiontext="{code} {name}" optionvalue="id" onChange={handleUpdateGroup(gc.id)} /></td>
                        <td><TextField size="sm" object={gc} prop="name" onChange={handleUpdateGroup(gc.id)} /></td>
                        <td><TextField size="sm" object={gc} prop="description" onChange={handleUpdateGroup(gc.id)} /></td>
                        <td>
                          <ButtonGroup>
                            <Button variant="outline-danger" size="sm" onClick={handleRemoveGroup(gc)}><FaTrash /></Button>
                            <Button variant="outline-primary" size="sm" onClick={handleAddGroup(gc)}><FaCopy /></Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}

                  </Fragment>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup>
              <Button onClick={handleSaveProject}>{t('common.Save')}</Button>
            </ButtonGroup>
          </Modal.Footer>

        </Modal>

        <Modal show={showNoGroupError} onHide={() => { setShowNoGroupError(false); }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {' '}
              {t('scenario.No group error')}
            </Modal.Title>
          </Modal.Header>
        </Modal>

        <Modal show={showProjectScenarioDialog} onHide={() => { setShowProjectScenarioDialog(false); }}>
          <Modal.Header closeButton>
            <Modal.Title>
              {' '}
              {t('scenario.Add scenario')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ScenarioForm project={projectScenario} onChange={(projectScenario) => setProjectScenario(projectScenario)} scenarioError={scenarioError} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleCreateScenario()}>{t('scenario.Add scenario')}</Button>
          </Modal.Footer>
        </Modal>

        <div className="page-header">
          <div className="d-flex align-items-start">
            <h1 className="mb-0 mr-2">{t('scenario.Scenarios')}</h1>
            <Badge pill variant="primary">
              {scenarios.length}
            </Badge>
          </div>

          {!loading && scenarios.length !== 0 && (
            <div>
              {/* <Button
                varient="primary"
                onClick=
                {() =>
                  {
                    window.location.reload();
                  }
                }
                className="mr-2"
              >

                {' '}
                {t('common.Refresh')}
              </Button> */}

              {/* <Button
                variant="primary"
                onClick={() => {
                  if (project.groups.length > 0){
                    setShowEditGroupsDialog(true)
                  } else {
                    setShowNoGroupError(true);
                  }}}
                className="mr-2"
              >
                <FaPen />
                {' '}
                {t('scenario.Edit groups')}
              </Button> */}

              <Button
                variant="primary"
                onClick={() => {
                  setProjectScenario({ ...emptyProjectScenario })
                  setShowProjectScenarioDialog(true);
                }}
              >
                <FaPlus />
                {' '}
                {t('scenario.New scenario')}
              </Button>
            </div>
          )}
        </div>

        {loading && (
          <div className="text-center">
            <Spinner variant="primary" animation="border" role="status">
              <span className="sr-only">{t('Loading...')}</span>
            </Spinner>
          </div>
        )}

        {!loading && scenarios.length === 0 && (
          <Alert variant="primary" className="text-center p-5 m-3">
            <h2 className="h4 mb-3">{t('scenario.No scenarios have been added to this project')}</h2>
            <Button
              variant="primary"
              onClick={() => {
                setProjectScenario(emptyProjectScenario);
                setShowProjectScenarioDialog(true);
              }}
            >
              <FaPlus />
              {' '}
              {t('scenario.Add scenario')}
            </Button>
          </Alert>
        )}

        <Accordion activeKey={selectedScenario.id} className="mb-4 scenarios">
          {!loading && scenarios.length > 0 && scenarios.sort((a, b) => (a.name < b.name ? -1 : 1)).map((scenario) => (
            <Scenario
              key={scenario.id + lastUpdate.toISOString()}
              project={project}
              scenario={scenario}
              scenarios={scenarios}
              setProject={setProject}
              selectedScenario={selectedScenario}
              setSelectedScenario={setSelectedScenario}
              onAdd={() => getScenarios()}
              onDelete={() => getScenarios()}
            />
          ))}
        </Accordion>

      </Container>
    </div>
  );
};
