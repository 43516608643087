import React, {
  useState, useEffect, useCallback, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaTrash,
  FaPen,
  FaInfoCircle,
  FaPlus,
} from 'react-icons/fa';
import {
  Container,
  Card,
  Button,
  Row,
  Table,
  ButtonGroup,
  Alert,
  Popover,
  OverlayTrigger,
  ToggleButton,
} from 'react-bootstrap';
import { TextField, Typeahead } from '../common/components';
import { renderTooltip, humanize } from '../common/helpers';
import MaterialForm from '../database/MaterialForm';
import Api from '../../services/api';
import { withSettingsStore } from '../common/settings-context';
import CalculationHeader from './CalculationHeader';
import './Forms.scss';

export default withSettingsStore(({
  element,
  getUnitById,
  onChange,
  isValid,
  saveCalculations,
  project,
  organizationId,
  user,
  getUnitByCode,
  productError,
  setProductError
}) => {
  const { t } = useTranslation();

  // Initial data constructors
  const emptyMaterial = useCallback(() => ({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 0,
    conversions: [],
    organizationId: organizationId,
    source: '',
  }), []);

  const emptyProduct = useCallback(() => ({
    id: 0,
    materialId: 0,
    name: null,
    comment: null,
    description: null,
    conversionId: 0,
    conversionFactor: 1,
    factor: 1,
    totalEmission: 0,
    material: emptyMaterial(),
  }), [emptyMaterial]);

  // Object state
  const hasProducts = (e) => e !== undefined && e.products !== undefined && e.products.length > 0;
  const [products, setProducts] = useState(hasProducts(element) ? element.products : []);
  const [showMaterialForm, setShowMaterialForm] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [errors, setErrors] = useState([]);
  const toggleMaterialForm = (i) => { 
    setShowMaterialForm(showMaterialForm === i ? null : i);
  };
  const [editMaterial, setEditMaterial] = useState(emptyMaterial());

  // Event handlers
  const handleInputChange = (orig) => (obj) => (event) => {
    const { name, value } = event.currentTarget;

    const targetObject = products.find((p) => p === orig);
    if (targetObject !== undefined) {
      targetObject[name] = value;

      setProducts([...products]);

      element.products = products;
      onChange !== undefined && onChange(element);
    }
  };

  const handleMaterialSelect = (product, material) => {
    const target = products.find((p) => p === product);
    target.material = material;
    target.materialId = material.id;
    target.conversionId = getMatchingConversionId(target);
    target.name = material.name;
    setProducts([...products]);
    setProductError(false);
  };

  const handleSaveEditMaterial = async (product) => {
    const targetProduct = products.find((p) => p === product);
    const errorLog = []
    if (!editMaterial.name || editMaterial.name.trim() == '') {
      errorLog.push('name')
    } 
    if (!editMaterial.source || editMaterial.source.trim() == '') {
      errorLog.push('source')
    }
    setErrors(errorLog);
    if (errorLog.length > 0) {
      return;
    }
    // const targetProduct = products.find((p) => p === product);
    let response = null;
    // Select the correct operator
    if (editMaterial.id === undefined || editMaterial.id === null || editMaterial.id === 0) {
      response = await Api().materials().post(editMaterial);
    } else {
      response = await Api().materials(editMaterial.id).update(editMaterial);
    }
    targetProduct.materialId = response.id;
    targetProduct.material = response;
    targetProduct.conversionId = getMatchingConversionId(targetProduct);
    targetProduct.name = response.name;
    setProducts([...products]);
    setShowMaterialForm(null);
  };

  // Callbacks
  const getMatchingConversionId = useCallback((product) => {
    if (element === undefined || element === null || product === undefined || product === null || product.material === undefined) {
      return 0;
    }
    const fromUnitId = element.unitId;
    const toUnitId = product.material.unitId;

    const conversion = product.material.conversions.find((c) => Number(c.fromUnitId) === fromUnitId && Number(c.toUnitId) === toUnitId);
    if (conversion !== undefined) {
      return conversion.id;
    }
    return 0;
  }, [element]);

  const getConversionComment = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found !== undefined && found !== null && found.comment !== null) {
      return found.comment;
    }
    return '';
  }, []);

  const getConversionFactor = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found) {
      let factor;
      if (found.inverse === true) {
        factor = 1 / found.factor;
      } else {
        factor = found.factor;
      }
      return (factor > 0 && factor < 0.001) ? 
        factor.toExponential(3) : 
        humanize.amount_long(factor, 3);
    }
    return null;
  }, []);

  // Effects
  useEffect(() => {
    setProducts(hasProducts(element) ? element.products.map((p) => ({ ...p, conversionId: getMatchingConversionId(p) })) : []);
  }, [element, emptyProduct, getMatchingConversionId]);

  useEffect(() => {
    element.products = products;
    onChange !== undefined && onChange(element);
  }, [products, onChange, element]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('installation.element-info-details2-row-1')}
          </Row>
          <Row>
            {t('installation.element-info-details2-row-2')}
          </Row>
          <Row>
            {t('installation.element-info-details2-row-3')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );



  //Gets all materials from the database based on organisation ID
  const searchMaterials = async ({ search }) => {
    const results = await Api().materials().get({ search, filterByOrgId: organizationId });
      let res = results.items
        .filter((item) => item.organizationId === organizationId)
        .map((r) => ({ ...r, unit: getUnitById(r.unitId).abbreviation }));
      switch (selectedFilter) {
        case 1:
          res = res.filter((material) => material.createdBy === user.id);
          break;
        case 2:
          res = res.filter((material) => material.createdBy === null);
          break;
        default:
          // No filtering needed for all materials
          break;
      }
    return res;
  };

  // JSX
  return (
    <>
      <CalculationHeader
        element={element}
        totalEmissionType="totalProductEmission"
        isValid={isValid}
        saveCalculations={saveCalculations}
        organizationId = {organizationId}
      />
      {products.length > 0 ? (
        <>
          <Card className="mb-2">
            <Card.Body>
              <Container fluid>
                <Row className="d-flex m-0 align-items-center">
                  <em><strong style={{ color: '#009df0' }}> {t('common.Material search filters')}: </strong></em>
                  <ButtonGroup className="d-flex align-items-center">
                    <ToggleButton
                      type="radio"
                      variant="link"
                      className={`custom-toggle-button ${selectedFilter === 0 ? 'selected-filter' : ''}`}
                      name="filter"
                      value={0}
                      checked={selectedFilter === 0}
                      onChange={(e) => setSelectedFilter(0)}
                    >
                      {t('common.All materials')}
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="link"
                      className={`custom-toggle-button ${selectedFilter === 1 ? 'selected-filter' : ''}`}
                      name="filter"
                      value={1}
                      checked={selectedFilter === 1}
                      onChange={(e) => setSelectedFilter(1)}
                    >
                      {t('common.My materials')}
                    </ToggleButton>
                    <ToggleButton
                      type="radio"
                      variant="link"
                      className={`custom-toggle-button ${selectedFilter === 2 ? 'selected-filter' : ''}`}
                      name="filter"
                      value={2}
                      checked={selectedFilter === 2}
                      onChange={(e) => setSelectedFilter(2)}
                    >
                      {t('common.Published materials')}
                    </ToggleButton>
                  </ButtonGroup>
                </Row>
                  <Row>
                  <Table>
                    <thead>
                      <tr>
                        <th>{t('product.Material')}</th>
                        <th>
                          {t('common.Ratio')}
                          {' '}
                          <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={popover}>
                            <FaInfoCircle />
                          </OverlayTrigger>
                        </th>
                        <th>{t('common.Comment')}</th>
                        <th>
                          {t('common.Emission')}
                          {' '}
                          (
                          <span style={{ textTransform: 'none' }}>{getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}</span>
                          )
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product, i) => (
                        <Fragment key={i}>
                          <tr>
                            <td>

                            <Typeahead 
                            value={product.material.name} 
                            onSelect={(mat) => { handleMaterialSelect(product, mat); setErrors([]);}} 
                            placeholder={
                              selectedFilter === 1
                                ? t('common.Search my materials')
                                : selectedFilter === 2
                                  ? t('common.Search published materials')
                                  : t('common.Search all materials')
                            }
                            source={searchMaterials} 
                            resultText="{name}, {unit}" 
                            resultIcon={(res) => (res.createdBy > 0 ? 'user' : 'database')} resultBackgroundColor={(res) => res.isLinked && '#d4edda'} />
                            </td>
                            <td><TextField object={product} prop="factor" onChange={handleInputChange(product)} /></td>
                            <td><TextField object={product} prop="comment" onChange={handleInputChange(product)} /></td>
                            <td><TextField disabled object={product} prop="totalEmission" onChange={() => { }} /></td>
                            <td>
                              <ButtonGroup className="float-right">
                                <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('product.Edit material/product and conversions', 1))}>
                                  <Button 
                                  variant="outline-info" 
                                  onClick={() => {
                                    setErrors([]);
                                    toggleMaterialForm(i);
                                  }}><FaPen /></Button>
                                </OverlayTrigger>
                              <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('product.Delete material/product', 2))}>
                                <Button variant="outline-danger" onClick={() => { setProducts([...products.filter((p) => p !== product)]); }}><FaTrash /></Button>
                              </OverlayTrigger>
                              </ButtonGroup>
                            </td>
                          </tr>
                          {product.material.name == "" ? (
                            <tr>
                              <td colSpan="8">
                                <Alert variant="warning">{t('product.Please select a material')}</Alert>
                              </td>
                            </tr>
                          ) : (
                            product.material.unitId !== element.unitId && product.conversionId <= 0 && (
                              <tr>
                                <td colSpan="8">
                                  <Alert variant="danger">
                                    {t(`product.Conversion not present. Please add a material conversion from`) + ` '${getUnitById(element.unitId).symbol}' to '${getUnitById(product.material.unitId).symbol}'.`}
                                  </Alert>
                                </td>
                              </tr>
                            ))}
                          {(product.materialId > 0 && product.conversionId != 0) && (
                            <tr>
                              <td colSpan="8">
                                <Alert variant="info">
                                  <strong>{t('common.Conversion factor used')}:</strong> {`${getConversionFactor(product.material.conversions, product.conversionId)} ${getUnitById(product.material.unitId).symbol}/${getUnitById(element.unitId).symbol}`}
                                  {getConversionComment(product.material.conversions, product.conversionId).trim() && (
                                    <>
                                      &nbsp;&nbsp; - &nbsp;&nbsp; <strong>{t('common.Conversion comment')}:</strong> '{getConversionComment(product.material.conversions, product.conversionId)}'
                                    </>
                                  )}
                                </Alert>
                              </td>
                            </tr>
                          )}
                          {showMaterialForm === i && (
                            <tr key={i}>
                              <td colSpan="6">
                                <div className="material-form">
                                  <MaterialForm
                                    material={product.material}
                                    fromUnitId={element.unitId}
                                    onChange={(m) => setEditMaterial(m)}
                                    isProduct
                                    project={project}
                                    organizationId={organizationId}
                                    errors = {errors}
                                  />
                                  <Button className="position-absolute r-1 b-1" onClick={() => handleSaveEditMaterial(product)}>
                                    {t('common.Save')}
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      ))}
                      {
                        productError &&
                        <td colSpan="11">
                          <Alert variant='danger'>
                            {t('product.Can not save without a material, please select a material')}
                          </Alert>
                        </td>
                      }
                    </tbody>
                  </Table>
                  <Button variant="outline-primary" className="mt-1" onClick={() => { setProducts([...products, emptyProduct()]); }}>
                    <FaPlus />
                    {` ${t('product.Add product')}`}
                  </Button>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </>
      ) : (
        <Alert variant="primary" className="text-center p-5">
          <h2 className="h5">{t('product.No products')}</h2>
          <Button variant="primary" onClick={() => { setProducts([...products, emptyProduct()]); }}>
            <FaPlus />
            {` ${t('product.Add product')}`}
          </Button>
        </Alert>
      )}
    </>
  );
});