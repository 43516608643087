import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  FaTrash, FaPen,
} from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';
import {
  Container,
  Button,
  Row,
  Table,
  Alert,
  ButtonGroup,
  FormControl,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { TextField, DropDown, Typeahead } from '../common/components';
import { renderTooltip, humanize } from '../common/helpers';
// import MaterialForm from '../database/MaterialForm';
import MachineForm from '../database/MachineForm';
import Api from '../../services/api';
import { SettingsContext } from '../common/settings-context';

import './Forms.scss';
import generators from './generators';

const InstallationLegacy = (props) => {
  const { t } = useTranslation();
  const {
    element: localElement,
    onChange,
    onRemove,
    organizationId,
    installation: localInstallation,
    project
  } = props;
  const [installation, setInstallation] = useState(localInstallation);
  const [element, setElement] = useState(localElement);
  const [showMaterialForm, setShowMaterialForm] = useState(false);
  const [showMachineForm, setShowMachineForm] = useState(false);
  const [editMaterial, setEditMaterial] = useState(generators.createMaterial());
  const [editMachine, setEditMachine] = useState(generators.createMachine());
  const [validationErrors, setValidationErrors] = useState([]);
  const [validationWarnings, setValidationWarnings] = useState([]);

  const { getUnitById, getUnitByCode} = useContext(SettingsContext);
  // Handlers
  const handleMaterialSelect = (installation, material) => {
    const conversion = material.conversions.find((c) => c.fromUnitId === element.unitId);
    setInstallation((inst) => ({
      ...inst,
      material,
      materialId: material.id,
      name: material.name,
      materialConversionId: conversion !== undefined ? conversion.id : 0,
    }));
  };

  const handleMachineSelect = (installation, machine) => {
    // Select the first matching conversion
    const conversion = machine.conversions.find((c) => c.fromUnitId === element.unitId);
    setInstallation((inst) => ({
      ...inst,
      machine,
      machineId: machine.id,
      name: machine.name,
      machineConversionId: conversion !== undefined ? conversion.id : 0,
    }));
  };

  // Handlers - material form
  const handleSaveEditMaterial = async (installation) => {
    let response = null;
    // Select the correct operator
    if (editMaterial.id === undefined || editMaterial.id === null || editMaterial.id === 0) {
      response = await Api().materials().post(editMaterial);
    } else {
      response = await Api().materials(editMaterial.id).update(editMaterial);
    }

    const conversion = installation.materialConversionId <= 0 && response.conversions.find((c) => c.fromUnitId === element.unitId);

    setInstallation((inst) => ({
      ...inst,
      material: response,
      materialId: response.id,
      name: response.name,
      materialConversionId: conversion !== undefined ? conversion.id : 0,
    }));

    setShowMaterialForm(false);
  };

  // Handlers - machine form
  const handleSaveEditMachine = async (installation) => {
    let response = null;

    // Based on the defined id, create a new machine or update the existing one
    if (editMachine.id === undefined || editMachine.id === null || editMachine.id === 0) {
      response = await Api().machines().post(editMachine);
    } else {
      response = await Api().machines(editMachine.id).update(editMachine);
    }

    // If the installation has existing conversion selected
    // We should attempt to keep exactly that one selected
    let conversion = response.conversions.find((c) => c.id === installation.machineConversionId);

    // Find the best matching conversion
    if (conversion === undefined) {
      conversion = response.conversions.find((c) => c.fromUnitId === element.unitId);
    }

    // Update installation with the new conversion (if it was found)
    setInstallation((inst) => ({
      ...inst,
      machine: response,
      machineId: response.id,
      name: response.name,
      machineConversionId: conversion !== undefined ? conversion.id : 0,
    }));

    setShowMachineForm(false);
  };

  const handleInputChange = (orig) => (obj) => (event) => {
    const { name, value } = event.currentTarget;

    const targetObject = orig;
    if (targetObject !== undefined) {
      targetObject[name] = value;

      setInstallation({ ...targetObject });
      onChange !== undefined && onChange({ ...targetObject });
    }
  };

  const getConversionById = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found !== undefined) {
      return found;
    }
    return { id: 0, fromUnitId: 0, toUnitId: 0 };
  }, []);

  const validate = useCallback(() => {
    // Check conversion pipeline
    const errors = [];
    const warnings = [];
    const sourceUnit = element.unitId;
    const materialConversion = installation.materialConversionId !== undefined && getConversionById(installation.material.conversions, installation.materialConversionId);
    const machineConversion = installation.machineConversionId !== undefined && getConversionById(installation.machine.conversions, installation.machineConversionId);
    const materialMachineConversion = materialConversion.toUnitId === machineConversion.fromUnitId;
    const machineUnit = machineConversion.toUnitId;
    const machineEmission = installation.machine !== undefined && getEmissionByUnitId(installation.machine.emissions, machineUnit).id;
    const sourceMachineEmission = installation.machine !== undefined && getEmissionByUnitId(installation.machine.emissions, sourceUnit).id;

    if (sourceMachineEmission > 0) {
      warnings.push({ installationId: installation.id, text: 'installation.Note emissions are calculated directly using machine emissions' });
      return;
    }

    if (machineEmission >= 0 && machineConversion.fromUnitId === sourceUnit) {
      warnings.push({ installationId: installation.id, text: 'installation.Note emissions are calculated directly using machine' });
      return;
    }

    if (!materialMachineConversion) {
      errors.push({ installationId: installation.id, text: 'installation.Material output unit does not match machine input unit' });
      return;
    }

    if (machineEmission.id <= 0) {
      errors.push({ installationId: installation.id, text: 'installation.Machine does not have emissions defined for the unit' });
      return;
    }

    if (sourceUnit <= 0 || materialMachineConversion || machineUnit <= 0 || machineEmission.id <= 0) {
      // setValidationErrors("Invalid input")
    }
    setValidationErrors(errors);
    setValidationWarnings(warnings);
  }, [element.unitId, getConversionById, installation]);

  const getEmissionByUnitId = (emissions, unitId) => {
    const found = emissions.find((e) => e.fromUnitId === unitId);
    if (found !== undefined) {
      return found;
    }
    return {
      id: 0,
    };
  };

  useEffect(() => {
    validate();

    onChange !== undefined && onChange({ ...installation });
  }, [installation, validate]);

  useEffect(() => {
    if (localElement !== null) {
      setElement(localElement);
    }
  }, [localElement]);
  useEffect(() => {
    if (localInstallation !== null) {
      setInstallation(localInstallation);
    }
  }, [localInstallation]);
  // Callbacks
  const conversionOptions = useCallback((material) => [{
    longName: getUnitById(material.unitId).abbreviation,
  }, ...material.conversions.map((c) => ({ ...c, longName: `${getUnitById(c.fromUnitId).abbreviation} => ${getUnitById(c.toUnitId).abbreviation}` }))], [getUnitById]);

  const machineConversionOptions = useCallback((machine) =>
    // Target types
    [{
      longName: getUnitById(machine.conversions.unitId).abbreviation,
    }, ...machine.conversions.map((c) => ({ ...c, longName: `${getUnitById(c.fromUnitId).abbreviation} / ${getUnitById(c.toUnitId).abbreviation}` }))].filter((c) => machine.emissions.find((e) => e.fromUnitId === c.toUnitId) !== undefined),
  [getUnitById]);

  const getConversionComment = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found !== undefined && found !== null) {
      return found.comment;
    }
    return '';
  }, []);

  const getConversion = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found) {
      return found;
    }
    return null;
  }, []);

  const getConversionFactor = useCallback((conversions, conversionId) => {
    const found = conversions.find((c) => Number(c.id) === Number(conversionId));
    if (found) {
      let factor;
      if (found.inverse === true) {
        factor = 1 / found.factor;
      } else {
        factor = found.factor;
      }
      return (factor > 0 && factor < 0.001) ? 
        factor.toExponential(3) : 
        humanize.amount_long(factor, 3);
    }
    return null;
  }, []);

  // Other
  const searchMaterials = async ({ search }) => {
    const results = await Api().materials().get({ search, filterByOrgId: organizationId  });
    return results.items.map((r) => ({ ...r, unit: getUnitById(r.unitId).abbreviation }));
  };

  const searchMachines = async ({ search }) => {
    const {items} = await Api().machines().getMachinesByOrgId({ id: organizationId, search, includeLinked: false  });
    console.log(items);
    return items;
  }

console.log(installation);
  // Popovers
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        {' '}
        {t('installation.element-info-title')}
      </Popover.Title>
      <Popover.Content>
        <Container>
          <Row>
            {t('installation.Select the conversion according to the element-specific installation')}
          </Row>
          <Row>
            {t('installation.A description of the conversion is displayed on a blue background')}
          </Row>
        </Container>
      </Popover.Content>
    </Popover>
  );
  
  return (
    <Container fluid>
      <Row className="installation__row">
        <Table>
          <thead>
            <tr>
              <th>{t('database.Machine')}</th>
              <th>
                {t('installation.Installation achievement')}
                {' '}
                <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
                  <FiAlertCircle />
                </OverlayTrigger>
              </th>
              <th />
              <th>{t('common.Comment')}</th>
              <th>
                  {t('common.Emission')}
                  {' '}
                  (
                  <span style={{ textTransform: 'none' }}>{getUnitByCode('kgco2eq', organizationId)?.[0]?.symbol}</span>
                  )
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="no-border-row">              
              <td>
              <Typeahead value={installation.machine.name} onSelect={(mat) => { handleMachineSelect(installation, mat); }} source={searchMachines} resultText="{name} - {description}" resultIcon={(res) => (res.createdBy > 0 ? 'user' : 'database')} placeholder={t('installation.Search machine')} />
              </td>
              <td>
                {installation.machine.id !== undefined && <DropDown object={installation} prop="machineConversionId" onChange={handleInputChange(installation)} options={machineConversionOptions(installation.machine)} optiontext="longName" optionvalue="id" />}
                {installation.machine.id === undefined && <FormControl as="select"><option>{t('installation.Select machine')}</option></FormControl>}
              </td>
              <td>
              <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('installation.Edit machine and conversions', 1))}>
                <Button variant="outline-info" onClick={() => { setShowMachineForm((state) => !state); }}><FaPen /></Button>
              </OverlayTrigger>
              </td>
              <td width='500px' ><TextField object={installation} prop="comment" onChange={handleInputChange(installation)} /></td>
              <td ><TextField disabled object={installation} prop="totalEmission" onChange={handleInputChange(installation)} /></td>
              <td>
                <ButtonGroup>
                <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip(t('installation.Delete installation', 2))}>
                  <Button variant="outline-danger" onClick={() => { onRemove(); }}><FaTrash /></Button>
                </OverlayTrigger>
                </ButtonGroup>
              </td>
            </tr>
            <tr className="no-border-row">
              <td colSpan="11">
                {/* {(installation.machineConversionId > 0 || installation.materialConversionId > 0) && (
                <>
                  {getConversionComment(installation.material.conversions, installation.materialConversionId) !== '' && (
                  <Alert variant="info">
                    {t('product.Material')}
                    :
                    {' '}
                    {getConversionComment(installation.material.conversions, installation.materialConversionId)}
                  </Alert>
                  )}
                  {getConversionComment(installation.machine.conversions, installation.machineConversionId) !== '' && (
                  <Alert variant="info">
                    {t('installation.Installation')}
                    :
                    {' '}
                    {getConversionComment(installation.machine.conversions, installation.machineConversionId)}
                  </Alert>
                  )}
                </>
                )} */}
                {(installation.machineConversionId > 0) && (
                  <Alert variant="info">
                    <strong>{t('common.Conversion factor used')}:</strong> {`${getConversionFactor(installation.machine.conversions, installation.machineConversionId)} ${getUnitById(getConversion(installation.machine.conversions, installation.machineConversionId).toUnitId).symbol}/${getUnitById(getConversion(installation.machine.conversions, installation.machineConversionId).fromUnitId).symbol}`}
                    {getConversionComment(installation.machine.conversions, installation.machineConversionId).trim() && (
                      <>
                        &nbsp;&nbsp; - &nbsp;&nbsp; <strong>{t('common.Conversion comment')}:</strong> '{getConversionComment(installation.machine.conversions, installation.machineConversionId)}'
                      </>
                    )}
                  </Alert>
                )}
                {validationErrors.filter((v) => v.idx === i).map((error) => <Alert variant="danger">{t(error.text)}</Alert>)}
                {validationWarnings.filter((v) => v.idx === i).map((warning) => <Alert variant="warning">{t(warning.text)}</Alert>)}
                {showMachineForm && (
                  <div className="material-form" style={{ marginLeft:'1px', marginTop: '1px'}}>
                    <MachineForm
                      readOnly={installation.machine.name !== '' }
                      organizationId={organizationId}
                      machine={installation.machine}
                      fromUnitId={getConversionById(installation.material.conversions, installation.materialConversionId).toUnitId}
                      onChange={(m) => setEditMachine(m)}
                      project = {props.project}
                      isInstallation
                      editable={true}
                      hideAddEmission={true}
                      hideTrash={false}
                    />
                    <Button className="position-absolute r-1 b-1" onClick={() => handleSaveEditMachine(installation)}>{t('common.Save')}</Button>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

InstallationLegacy.propTypes = {
  installation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired,
};
export default InstallationLegacy;
