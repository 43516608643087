import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  Button,
  Modal,
} from 'react-bootstrap';
import { defaultHandleChange } from '../common/helpers';
import { DropDown } from '../common/components';
import Api from '../../services/api';
import loglevel from '../../services/loglevel';

const InviteDialog = ({ projectId, show, onClose, currentUser,getUsers }) => {
  const { t } = useTranslation();
  const emptyInvite = () => ({
    projectId: 0,
    roleId: 0,
    email: '',
  });
  const [showDialog, setShowDialog] = useState(!!show);
  const [invite, setInvite] = useState({ ...emptyInvite(), projectId });
  const [roles, setRoles] = useState([]);

  const processInvite = () => {
    if (invite.email === '' || invite.projectId === 0 || invite.roleId === 0 || currentUser.email.toLowerCase() === invite.email.toLowerCase()) {      
      alert(t('project.Invite to project error'))
    } else {
      Api().projects(projectId).inviteUser(invite).then(response => {
        onClose !== undefined && onClose();
        getUsers();
      });
    }
  };

  const getRoles = useCallback(async () => {
    if (projectId !== undefined && projectId > 0) {
      const r = await Api().projects(projectId).roles().get();
      console.log("Roles", r);
      return r;
    }
  }, [projectId]);

  const handleRoleChange = (obj) => (e) => {
    const key = e.currentTarget.name;
    const { value } = e.currentTarget;
    setInvite((prev) => (
      {
        ...prev,
        [key]: value,
      }));
  };

  useEffect(() => {
    let isMounted = true;
    getRoles().then((roles) => isMounted && setRoles(roles));
    return () => { isMounted = false; };
  }, [getRoles, projectId]);
  useEffect(() => {
    setShowDialog(show);
  }, [show]);
  useEffect(() => {
    loglevel.info(invite);
  }, [invite]);

  return (
    <Modal show={showDialog} onHide={() => { setShowDialog(false); onClose(); }}>
      <Modal.Header>
        <Modal.Title>{t('project.Invite to project')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="email">
            <Form.Label>{t('project.Email')}</Form.Label>
            <Form.Control name="email" defaultValue={invite.name} type="text" placeholder={t('project.Enter email')} onChange={(e) => defaultHandleChange(e, setInvite)} />
          </Form.Group>
          <Form.Group controlId="projectRole">
            <Form.Label>{t('project.Project role')}</Form.Label>
            <DropDown object={invite} prop="roleId" options={roles} optiontext="name" optionvalue="id" onChange={handleRoleChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => processInvite()}>{t('project.Send invite')}</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default InviteDialog;
