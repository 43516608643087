/* eslint-disable linebreak-style */
import React, {
  useState, useEffect, useCallback, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  Modal,
  ButtonGroup,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Badge,
  Spinner,
  Pagination,
} from 'react-bootstrap';
import {
  FaPlus, FaTrash, FaPen, FaEye,
} from 'react-icons/fa';
import Api from '../../services/api';
import MachineForm from './MachineForm';
import { withSettingsStore } from '../common/settings-context';
import notFound from '../../assets/no-data.png';
import { sortObjectArray } from '../../utils/utilityFunctions';
import { humanize } from '../common/helpers';

const Machines = withSettingsStore(({
  displayError, units, isInRole, organizationSelect, getUnitById, getUnitByCode
}) => {
  const { t } = useTranslation();
  const itemsPerPageOptions = [
    { name: '20', value: 20 },
    { name: '50', value: 50 },
    { name: '100 ', value: 100 },
  ];
  const [machines, setMachines] = useState([]);
  const [showMachineDialog, setShowMachineDialog] = useState(false);
  const emptyMachine = () => ({
    id: 0,
    name: '',
    description: '',
    emission: 0,
    unitId: 0,
    conversions: [],
    organizationId: 0,
  });
  const [newMachine, setNewMachine] = useState(emptyMachine());
  const [searchText, setSearchText] = useState('');

  const [machineElements, setMachineElements] = useState([]);
  const [machineTemplates, setMachineTemplates] = useState([]);
  const [viewMachineId, setViewMachineId] = useState(0);
  const [loadingElements, setLoadingElements] = useState(false);
  const [showDeleteMachineDialog, setShowDeleteMachineDialog] = useState(false);
  const [machineToDelete, setMachineToDelete] = useState(emptyMachine());
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [numItems, setNumItems] = useState(50);
  const [sortBy, setSortBy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  // const getUnitAbbreviation = useCallback((unitId) => {
  //   const unit = units.find((u) => u.id === unitId);
  //   if (unit === undefined) {
  //     return '';
  //   }
  //   return unit.abbreviation;
  // }, [units]);

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const retrieveMachines = useCallback(async () => {
    clearTimeout(currentTimeout);
    setLoading(true);
    setCurrentTimeout(setTimeout(async () => {
      const m = await Api().machines().getMachinesByOrgId({
        limit: numItems, offset: page * numItems, sortBy, id: organizationSelect, includeLinked: true,
        search: searchText, limit: numItems, offset: page * numItems, sortBy, id: organizationSelect, includeLinked: false
      });
      const sortedByEmission = sortObjectArray(m.items, 'emission', true);

      setMachines(sortedByEmission);
      if (numItems > 0) {
        setLastPage(Math.floor(m.count / numItems));
        setPages((current) => {
          // iterate 1 ... lastPage
          current = [];
          for (let i = 0; i <= Math.floor(m.count / numItems); i++) {
            current.push(i + 1);
          }
          return current;
        });
      }

      setLoading(false);
    }, 1000));
  }, [searchText, numItems, page, sortBy, organizationSelect]);
  console.log(machines);

  useEffect(() => {
    setPage(0);
  }, [searchText, numItems]);

  const handleSaveMachine = async () => {
    const errorLog = []
    if (newMachine.name == '') {
      errorLog.push('name');
    }
    setFormErrors(errorLog);
    if (errorLog.length > 0) {
      return;
    }

    setLoading(true);
    // In the C# API logic, it will check if editable, and if so it will reassign previous org id.
    newMachine.organizationId = organizationSelect;
    if (newMachine.id > 0) {
      await Api().machines(newMachine.id).update(newMachine);
    } else {
      await Api().machines().post(newMachine);
    }
    setLoading(false);
    setShowMachineDialog(false);
    retrieveMachines();
  };

  const removeMachine = async (machine) => {
    if (machine.isLinked) {
      handleRemoveMachine(machine.id);
    } else {
      setMachineToDelete(machine);
      setShowDeleteMachineDialog(true);
    }
  };

  const handleRemoveMachine = async (id) => {
    try {
      await Api().machines(id).delete();
    } catch (e) {
      displayError(t('database.failed-to-remove-machine'));
    }
    retrieveMachines();
  };

  const handleEditMachine = (machine) => {
    setNewMachine(machine);
    setShowMachineDialog(true);
  };

  const handleNewMachine = () => {
    setNewMachine(emptyMachine());
    setShowMachineDialog(true);
  };

  const handleViewMachine = async (machine) => {
    setLoadingElements(true);
    setViewMachineId(machine.id);
    setMachineElements(await Api().machines(machine.id).elements());
    setMachineTemplates(await Api().machines(machine.id).templates());
    setLoadingElements(false);
  };

  // const filterBySearch = useCallback((m) => m.name.toLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0, [searchText]);

  useEffect(() => {
    if (organizationSelect !== null) {
      retrieveMachines();
    }
  }, [organizationSelect, retrieveMachines]);

  return (
    <div>
      <Modal show={showDeleteMachineDialog} onHide={() => { setShowDeleteMachineDialog(false); }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('database.Delete machine')}

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t('common.Are you sure you want to delete')}
            {' '}
            {machineToDelete.name}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={() => setShowDeleteMachineDialog(false)}>
            {' '}
            {t('common.Cancel')}
          </Button>
          <Button variant="outline-danger" onClick={() => { handleRemoveMachine(machineToDelete.id); setShowDeleteMachineDialog(false); }}>
            <FaTrash />
            {' '}
            {t('common.Delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMachineDialog} onHide={() => { setShowMachineDialog(false); }} dialogClassName="form-large">
        <Modal.Header closeButton>
          <Modal.Title>{t('database.New machine')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MachineForm 
            machine={newMachine} 
            organizationId={organizationSelect} 
            onChange={(m) => setNewMachine(m)} 
            editable={true}
            hideAddEmission={false}
            hideTrash={isInRole('Admin')} 
            editMachineDetails={isInRole('Admin')}
            editEmissionDetails={isInRole('Admin')}          
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSaveMachine()}>{t('common.Save')}</Button>
        </Modal.Footer>
      </Modal>
      <div className="button-toolbar">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('common.Search')}</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl onChange={(e) => { setSearchText(e.target.value); }} />
        </InputGroup>
        <InputGroup className="ml-1">
          <FormControl as="select" value={numItems} onChange={(e) => setNumItems(Number(e.target.value))}>
            {itemsPerPageOptions.map((o) => (
              <option key={o.value} value={o.value}>
                {o.name}
                {' '}
                {t('database.per page')}
              </option>
            ))}
            {/* <option key={0} value={0}>{t('database.All')}</option> */}
          </FormControl>
        </InputGroup>
        <div className="divider" />
        {
          isInRole('Admin') && (<ButtonGroup>
            <Button variant="outline-primary" onClick={() => handleNewMachine()}>
              <FaPlus />
              {' '}
              {t('database.New machine')}
            </Button>
          </ButtonGroup>)
        }
        
      </div>
      {(numItems > 0 && machines.length > 0) && (
        <Pagination className="sticky-pagination">
          <Pagination.First disabled={page <= 0} onClick={() => setPage(0)} />
          <Pagination.Prev disabled={page <= 0} onClick={() => setPage((p) => p - 1)} />
          {pages.map((p) => <Pagination.Item key={`page-${p}`} onClick={() => setPage(p - 1)}>{p}</Pagination.Item>)}
          <Pagination.Next disabled={page >= lastPage} onClick={() => setPage((p) => p + 1)} />
          <Pagination.Last disabled={page >= lastPage} onClick={() => setPage(lastPage)} />
        </Pagination>
      )}
      {loading ? (
        <div className="position-fixed text-center" style={{ left: '50%', top: '50%' }}>
          <Spinner variant="primary" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div id='machine' className='table-container'>
            <Table className="table-sm">
              <thead className="sticky1-header" style={{ height: '50px' }}>
                <tr>
                <th style={{ width: '2vw' }} />
                <th style={{ width: '10vw' }}>{t('common.Name')}</th>
                <th style={{ width: '10vw' }}>{t('common.Description')}</th>
                <th style={{ width: '5vw' }}>{t('database.Module')}</th>
                <th style={{ width: '5vw' }}>{t('common.Emission factor')}</th>
                <th style={{ width: '5vw' }}>{t('common.Unit')}</th>
                <th style={{ width: '5vw' }}>{t('common.Conversions')}</th>
                <th style={{ width: '10vw' }}>{t('database.Source')}</th>
                <th style={{ width: '5vw' }}>{t('database.Expiration Year')}</th>
                <th style={{ width: '5vw' }}>{t('database.Created')}</th>            
                <th style={{ width: '5vw' }}>{t('database.Created By')}</th>
                <th style={{ width: '10vw' }} />
                </tr>
              </thead>
              <tbody>
                {machines.length > 0 ? (
                  machines.map((m) => (
                    <Fragment key={m.id}>
                      <tr style={{ background: m.isLinked ? '#d4edda' : 'white' }}>
                        <td />
                        <td>{m.name}</td>
                        <td>{m.description}</td>
                        <td>{m.module}</td>
                        <td>
                          {m.emissions.length === 0 ? (
                            <span>-</span>
                          ) : (
                            m.emissions.map((c) => (
                              <Fragment key={c.fromUnitId}>
                                <div>{c.emission}</div>
                              </Fragment>
                            ))
                          )}
                        </td>
                        <td>
                          {m.emissions.length === 0 ? (
                            <span>{t('-')}</span>
                          ) : (
                            m.emissions.map((c) => (
                              <Fragment key={c.fromUnitId}>
                                <Badge className="m-1" variant="secondary">
                                  {getUnitByCode('kgco2eq', organizationSelect)?.[0]?.symbol} / {getUnitById(c.fromUnitId).symbol}
                                </Badge>
                              </Fragment>
                            ))
                          )}
                        </td>
                        <td>
                          {m.conversions.length === 0 ? (
                            <span>-</span>
                          ) : (
                            m.conversions.map((c) => (
                              <Badge key={c.id} className="m-1" variant="secondary">
                                {getUnitById(c.fromUnitId).symbol} &#8594; {getUnitById(c.toUnitId).symbol}
                              </Badge>
                            ))
                          )}
                        </td>
                        <td>
                          {m.emissions.map((e) => <div>{e.source}</div>)}
                        </td>
                        <td>{m.expirationYear === 0 ? " " : m.expirationYear}</td>
                        <td>{humanize.date(m.createdAt, 'date')}</td>
                      <td>{m.createdByName}</td>
                        <td className="text-right">
                          <ButtonGroup>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(t('database.Edit', 1))}
                            >
                              <Button variant="outline-info" onClick={() => { handleEditMachine(m); }}><FaPen /></Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(t('database.View', 2))}
                            >
                              <Button
                                disabled={!(isInRole('Admin'))}
                                variant="outline-info"
                                onClick={() => { handleViewMachine(m); }}
                              >
                                <FaEye />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip(t('database.Delete', 3))}
                            >
                              <Button variant="outline-danger" onClick={() => { removeMachine(m); }}><FaTrash /></Button>
                            </OverlayTrigger>
                          </ButtonGroup>
                        </td>
                      </tr>

                      {viewMachineId === m.id && (
                        <tr>
                          <td colSpan="9">
                            {loadingElements ? (
                              <div className="text-center">
                                <Spinner variant="primary" animation="border" role="status">
                                  <span className="sr-only">{t('Loading...')}</span>
                                </Spinner>
                              </div>
                            ) : (
                              <Table className="table-sm">
                                <thead>
                                  <tr>
                                    <th>{t('project.Project')}</th>
                                    <th>{t('scenario.Scenario')}</th>
                                    <th>{t('common.Code')}</th>
                                    <th>{t('common.Name')}</th>
                                    <th>{t('common.Unit')}</th>
                                    <th>{t('common.Description')}</th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr><td colSpan="6">{t('element.Elements')}</td></tr>
                                  {machineElements.map((e) => (
                                    <tr key={e.id}>
                                      <td>{e.projectName}</td>
                                      <td>{e.scenarioName}</td>
                                      <td>{e.code}</td>
                                      <td>{e.name}</td>
                                      <td>{e.unit}</td>
                                      <td>{e.description}</td>
                                      <td />
                                    </tr>
                                  ))}
                                  <tr><td colSpan="6">{t('element.Templates')}</td></tr>
                                  {machineTemplates.map((e) => (
                                    <tr key={e.id}>
                                      <td>{e.projectName}</td>
                                      <td>{e.scenarioName}</td>
                                      <td>{e.code}</td>
                                      <td>{e.name}</td>
                                      <td>{e.unit}</td>
                                      <td>{e.description}</td>
                                      <td />
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>)
                            }
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <tr style={{ height: '60vh' }}>
                    <td colSpan="10" className="text-center">
                      <div className="mt-4 d-flex-col">
                        <div className="inner-div">
                          <img src={notFound} className="img-responsive" style={{ height: '70px' }} alt="Not found" />
                        </div>
                        <div className="inner-div">
                          <h1 style={{ fontSize: "20px" }}>{t('database.Data Not Available in the Database')}</h1>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>

      )}

    </div>
  );
});

const renderTooltip = (name, id) => (
  <Tooltip id={id}>
    {name}
  </Tooltip>
);

export default Machines;
