import React, {
  useState, useEffect, useCallback, Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';
import {
  FaTrash, FaPen, FaMinus,
} from 'react-icons/fa';
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  ButtonGroup,
  InputGroup,
  Alert,
  Badge,
  Spinner,
  Tooltip,
  OverlayTrigger,
  Overlay,
  Popover,
} from 'react-bootstrap';
import { FiEdit2, FiFile, FiInfo, FiFilter } from 'react-icons/fi';
import sampleData from '../../../assets/sample-data.xlsx';
import Api from '../../../services/api';
import { withSettingsStore } from '../../common/settings-context';
import loglevel from '../../../services/loglevel';
import { DropDown, TextField } from '../../common/components';
import { clone } from '../../common/helpers';
import DKsampleData from '../../../assets/DK_SampleData.xlsx';
import FIsampleData from '../../../assets/FI_SampleData.xlsx';
import UKsampleData from '../../../assets/UK_SampleData.xlsx';
import DEsampleData from '../../../assets/DE_SampleData.xlsx';
import MCsampleData from '../../../assets/MC_SampleData.xlsx';

const ImportForm = withSettingsStore(({
  onChange,
  units,
  codes,
  // findUnit,
  groups,
  projectTypes,
  projectType,
  setValidImport,
  importing,
  showErrorRows,
  project,
}) => {
  const { t } = useTranslation();
  const [elements, setElements] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [file, setFile] = useState();
  const [requireCode, setRequireCode] = useState(false);
  const [reportType, setReportType] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [targetGroup, setTargetGroup] = useState({
    id: null,
  });

  const availableMappings = () => ([
    {
      enabled: true, name: 'code', text: 'common.Code', source: '', type: 'string', widget: 'TextField', selectable: true, search: ['tunniste', 'koodi'],
    },
    {
      enabled: true, name: 'groupName', text: 'common.Group', source: '', type: 'string', widget: 'TextField', selectable: true, search: ['ryhmä', 'nimi'],
    },
    {
      enabled: true, name: 'name', text: 'common.Name', source: '', type: 'string', widget: 'TextField', selectable: false, search: ['nimi'], ///
    },
    {
      enabled: false, name: 'description', text: 'common.Description', source: '', type: 'string', widget: 'TextField', selectable: true, search: ['kuvaus', 'selite'],
    },
    {
      enabled: true, name: 'quantity', text: 'common.Quantity', source: '', type: 'number', widget: 'TextField', selectable: false, search: ['määrä'],//
    },
    {
      enabled: true, name: 'unitId', text: 'common.Unit', source: '', type: 'unit', widget: 'UnitList', selectable: false, search: ['yksikkö'], ///
    },
    {
      enabled: true, name: 'transportDistance', text: 'common.Distance', source: 'transportDistance', type: 'number', widget: 'TextField', selectable: true, search: [], //
    },
    // {
    //   enabled: false, name: 'transportName', text: 'common.Transport name', source: 'transportName', type: 'string', widget: 'TextField', selectable: true, search: [],
    // },
    // {
    //   enabled: false, name: 'totalEmission', text: 'common.Total emission', source: '', type: 'number', widget: 'TextField', selectable: true, search: ['päästö'],
    // },
  ]);



  const [mappings, setMappings] = useState(availableMappings());

  const availableUnits = units.filter(unit => unit.organizationId === project.organizationId);

  /**
     *
     * @param {object} csvdata in array format
     * @param {string|array} keyword as string or array
     * @param {boolean} exact is the query for exact match
     */
  const findMappingByKeyword = (data, keyword, exact = true) => {
    let options = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const found = Object.keys(row).filter((k) => {
        if (exact) {
          if (Array.isArray(keyword)) {
            return keyword
              .map((kw) => kw?.toLowerCase()?.trim())
              .includes(row[k]?.toString()?.trim()?.toLowerCase());
          }
          return row[k].toString()?.toLowerCase()?.trim() === keyword?.toLowerCase().trim();
        }
        if (Array.isArray(keyword)) {
          const matches = keyword
            .map((kw) => row[k].toString()?.toLowerCase()?.search(kw?.toLowerCase()))
            .filter((kw) => kw >= 0);
          return matches.length > 0;
        }
        return row[k]?.toString()?.toLowerCase()?.search(keyword?.toLowerCase()) !== -1;
      });

      if (found.length > 0) {
        options = [...options, { row: i, mapping: found }];
      }
    }
    return options;
  };

  const firstMappingOrDefault = (m, def) => {
    if (m.length > 0) {
      return m[0].mapping[0];
    }

    return def;
  };

  const createAltUnits = (abbr) => [abbr.replace('²', '2'), abbr.replace('³', '3')];
  const findUnit = useCallback((str) => units.filter((f) => f.organizationId == project.organizationId).find((u) => {
    const abbr = u.abbreviation;
    const tests = [abbr, ...createAltUnits(abbr)];

    return tests.includes(str);
  }), [units]);

  const handleFile = (event) => {
    const fileList = event.target.files;

    const reader = new FileReader();
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      loglevel.info(data);

      // Iterate each row and get keys
      let rawHeaders = [...data.reduce((acc, row) => {
        Object.keys(row).forEach((key) => {
          acc.add(key);
        });
        return acc;
      }, new Set())].sort();

      loglevel.info(rawHeaders);
      // eslint-disable-next-line no-underscore-dangle
      const isForeReport = data[0] !== undefined && (data[0].__EMPTY_1 === 'KUSTANNUSARVIO RYHMITTÄIN' || data[0]['MÄÄRÄLUETTELO'] === 'Projekti:' || data[0]['KUSTANNUSARVIO RYHMITTÄIN'] !== undefined);
      // eslint-disable-next-line no-underscore-dangle
      const isIhkuReport = data[0] !== undefined && (data[1].__EMPTY === 'Hanke' || data[0].__EMPTY === 'Kustannusarvio rakenteen mukaan');

      if (isForeReport) {
        setReportType('FORE');

        const range = XLSX.utils.decode_range(ws['!ref']);

        let startRow = range.s.r;
        let endRow = range.e.r;
        let endColumn = range.s.c;

        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellValue = ws[XLSX.utils.encode_cell({ r: row, c: col })]?.v;
            if (cellValue === 'Tunniste') {
              startRow = row;
              break;
            }
          }
          if (startRow !== range.s.r) {
            break;
          }
        }

        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellValue = ws[XLSX.utils.encode_cell({ r: startRow, c: col })]?.v;
          if (cellValue === 'Määrä') {
            endColumn = col;
            break;
          }
        }

        for (let row = startRow; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellValue = ws[XLSX.utils.encode_cell({ r: row, c: col })]?.v;
            if (cellValue === 'Rakennusosat yhteensä') {
              endRow = row - 1;
              break;
            }
          }
          if (endRow !== range.e.r) {
            break;
          }
        }

        const rangeStart = { r: startRow, c: range.s.c };
        const rangeEnd = { r: endRow, c: endColumn };
        const newRange = { s: rangeStart, e: rangeEnd };

        data = XLSX.utils.sheet_to_json(ws, { header: startRow, range: newRange });

        rawHeaders = [...data.reduce((acc, row) => {
          Object.keys(row).forEach((key) => {
            acc.add(key);
          });
          return acc;
        }, new Set())].sort();

        console.log(data);
      }

      if (isIhkuReport) {
        setReportType('IHKU');

        const range = XLSX.utils.decode_range(ws['!ref']);
        const startRow = 15;

        const rangeStart = { r: startRow - 1, c: range.s.c };
        const rangeEnd = { r: range.e.r, c: range.e.c };
        const newRange = { s: rangeStart, e: rangeEnd };

        data = XLSX.utils.sheet_to_json(ws, { header: 15, range: newRange });

        for (let i = 0; i < data.length; i++) {
          if ('__EMPTY' in data[i]) {
            data[i]['Ryhmä'] = data[i]['__EMPTY'];
            delete data[i]['__EMPTY'];
          }
        }

        rawHeaders = [...data.reduce((acc, row) => {
          Object.keys(row).forEach((key) => {
            acc.add(key);
          });
          return acc;
        }, new Set())].sort();

        console.log(data);
      }

      setHeaders(rawHeaders.map((h, idx) => {
        let index = h;

        if (isForeReport || isIhkuReport) {
          index = idx + 1;
        }
        return {
          key: h,
          index,
          example: data?.map((r) => r[h]).filter((r) => r !== undefined).filter((r, i) => i < 5).join(', '),
        };
      }).sort((a, b) => a.index > b.index));

      setMappings(availableMappings());

      if (isForeReport || isIhkuReport) {
        if (isForeReport) {

          const fields = ['code', 'groupName', 'name', 'unitId', 'quantity'];

            const foundMappings = fields?.map((mappingName) => {
              const mapping = mappings.find((m) => m.name === mappingName);
              return findMappingByKeyword(data, mapping?.search, false);
            });

            const rowsByCount = foundMappings
            .reduce((acc, cur) => [...acc, ...cur.map((c) => c.row)], [])
            .sort((a, b) => a - b)
            .reduce((acc, cur) => {
              if (acc[cur] === undefined) {
                acc[cur] = 0;
              }
              acc[cur] += 1;
              return acc;
            }, {});

          const headerRow = Object.keys(rowsByCount)
            .sort((a, b) => rowsByCount[b] - rowsByCount[a])
            .map((r) => Number(r))[0];

          fields?.forEach((mappingName, idx) => {
            const mapping = mappings.find((m) => m.name === mappingName);
            const foundMapping = foundMappings[idx].filter((m) => m.row === headerRow);
          });

          if (rawHeaders[0] === 'Määrä'){
            mappings.find((m) => m.name === 'code').source = 'Tunniste';
            mappings.find((m) => m.name === 'groupName').source = '';
            mappings.find((m) => m.name === 'name').source = 'Rakennusosa';
            mappings.find((m) => m.name === 'unitId').source = 'Yks.';
            mappings.find((m) => m.name === 'quantity').source = 'Määrä';
          }
          setRawData(data);
        }

        if (isIhkuReport) {

            const fields = ['code', 'groupName', 'name', 'unitId', 'quantity', 'description'];

            const foundMappings = fields?.map((mappingName) => {
              const mapping = mappings.find((m) => m.name === mappingName);
              return findMappingByKeyword(data, mapping?.search, false);
            });

            const rowsByCount = foundMappings
            .reduce((acc, cur) => [...acc, ...cur.map((c) => c.row)], [])
            .sort((a, b) => a - b)
            .reduce((acc, cur) => {
              if (acc[cur] === undefined) {
                acc[cur] = 0;
              }
              acc[cur] += 1;
              return acc;
            }, {});

          const headerRow = Object.keys(rowsByCount)
            .sort((a, b) => rowsByCount[b] - rowsByCount[a])
            .map((r) => Number(r))[0];

          fields?.forEach((mappingName, idx) => {
            const mapping = mappings.find((m) => m.name === mappingName);
            const foundMapping = foundMappings[idx].filter((m) => m.row === headerRow);
            mapping.source = foundMapping.length > 0 ? foundMapping[0].mapping[0] : rawHeaders[0];
          });

          if (rawHeaders[0] === 'Koodi'){
            mappings.find((m) => m.name === 'code').source = 'Koodi';
            mappings.find((m) => m.name === 'groupName').source = 'Ryhmä';
            mappings.find((m) => m.name === 'name').source = 'Rakennusosa';
            mappings.find((m) => m.name === 'unitId').source = 'Yksikkö';
            mappings.find((m) => m.name === 'quantity').source = 'Määrä';
            mappings.find((m) => m.name === 'description').source = 'Panosvalinta';
          }
          setRawData(data);
        }

        // if (firstMappingOrDefault(findMappingByKeyword(data, 'Päästölaskelma', false)) === undefined) {
        //   mappings.find((m) => m.name === 'totalEmission').enabled = false;
        // }

        // Join data
        // const regexIsExtra = /^\+/;
        // const regexNumbers = /([0-9]+)/g;
        // const nameMapping = mappings.find((m) => m.name === 'name').source;

        // const rdata = data.reduce((acc, cur) => {
        //   const name = cur[nameMapping];
        //   if (name === undefined) {
        //     return acc;
        //   }
        //   if (regexIsExtra.test(name)) {
        //     if (regexNumbers.test(name)) {
        //       // Transport row. append to the latest
        //       const latest = acc[acc.length - 1];
        //       // Get kilometers
        //       const matches = [...name.matchAll(regexNumbers)];
        //       if (matches.length > 0) {
        //         const distance = Number(matches[matches.length - 1][0]);
        //         // Now create a new transport for the latest element and add this to it
        //         latest.transportDistance = distance;
        //         latest.transportName = name;
        //       }
        //     }
        //   } else {
        //     acc.push({
        //       ...cur, transportDistance: 0, transportName: '',
        //     });
        //   }
        //   return acc;
        // }, []);
        // setRawData(rdata);
      }

      else {
        const fields = ['code', 'groupName', 'name', 'description', 'quantity', 'unitId', 'transportDistance'];
        const foundMappings = fields?.map((mappingName) => {
          const mapping = mappings.find((m) => m.name === mappingName);
          return findMappingByKeyword(data, mapping?.search, false);
        });

        const rowsByCount = foundMappings
          .reduce((acc, cur) => [...acc, ...cur.map((c) => c.row)], [])
          .sort((a, b) => a - b)
          .reduce((acc, cur) => {
            if (acc[cur] === undefined) {
              acc[cur] = 0;
            }
            acc[cur] += 1;
            return acc;
          }, {});

        const headerRow = Object.keys(rowsByCount)
          .sort((a, b) => rowsByCount[b] - rowsByCount[a])
          .map((r) => Number(r))[0];

          fields?.forEach((mappingName, idx) => {
            const mapping = mappings.find((m) => m.name === mappingName);
            const foundMapping = foundMappings[idx].filter((m) => m.row === headerRow);
            mapping.source = foundMapping.length > 0 ? foundMapping[0].mapping[0] : rawHeaders[0];
        });

        // Sample data mappings
        if ((rawHeaders[0] === 'Tunniste') || (rawHeaders[0] === 'Ryhmä') || (rawHeaders[0] === 'Nimi') || (rawHeaders[0] === 'Kuvaus') || (rawHeaders[0] === 'Määrä') || (rawHeaders[0] === 'Yksikkö') || (rawHeaders[0] === 'Matka')) {
          mappings.find((m) => m.name === 'code').source = 'Tunniste';
          mappings.find((m) => m.name === 'groupName').source = 'Ryhmä';
          mappings.find((m) => m.name === 'name').source = 'Nimi';
          mappings.find((m) => m.name === 'description').source = 'Kuvaus';
          mappings.find((m) => m.name === 'quantity').source = 'Määrä';
          mappings.find((m) => m.name === 'unitId').source = 'Yksikkö';
          mappings.find((m) => m.name === 'transportDistance').source = 'Matka';
        }

        if ((rawHeaders[0] === 'Code') || (rawHeaders[0] === 'Group') || (rawHeaders[0] === 'Name') || (rawHeaders[0] === 'Description') || (rawHeaders[0] === 'Quantity') || (rawHeaders[0] === 'Unit') || (rawHeaders[0] === 'Distance')) {
          mappings.find((m) => m.name === 'code').source = 'Code';
          mappings.find((m) => m.name === 'groupName').source = 'Group';
          mappings.find((m) => m.name === 'name').source = 'Name';
          mappings.find((m) => m.name === 'description').source = 'Description';
          mappings.find((m) => m.name === 'quantity').source = 'Quantity';
          mappings.find((m) => m.name === 'unitId').source = 'Unit';
          mappings.find((m) => m.name === 'transportDistance').source = 'Distance';
        }

        if ((rawHeaders[0] === 'Kode') || (rawHeaders[0] === 'Gruppe') || (rawHeaders[0] === 'Navn') || (rawHeaders[0] === 'Beskrivelse') || (rawHeaders[0] === 'Mængde') || (rawHeaders[0] === 'Enhed') || (rawHeaders[0] === 'Afstand')) {
          mappings.find((m) => m.name === 'code').source = 'Kode';
          mappings.find((m) => m.name === 'groupName').source = 'Gruppe';
          mappings.find((m) => m.name === 'name').source = 'Navn';
          mappings.find((m) => m.name === 'description').source = 'Beskrivelse';
          mappings.find((m) => m.name === 'quantity').source = 'Mængde';
          mappings.find((m) => m.name === 'unitId').source = 'Enhed';
          mappings.find((m) => m.name === 'transportDistance').source = 'Afstand';
        }

        setRawData(data);
      }

      setMappings(mappings);
    };
    setFile(fileList[0]);
    reader.readAsBinaryString(fileList[0]);
  };

  const isAlmostEmptyRow = (row) => {
    const keys = Object.keys(row).map((k) => row[k]).filter((v) => v !== 0 && v !== '');
    const totalValues = keys.length;

    // HACK Just to get it to work.
    // It seems to be enough to just check if there is less than 4 values filled
    if (totalValues < 4) {
      return true;
    }

    return false;
  };

  const updateTemplates = async (sourceElements) => {
    loglevel.info('Updating templates...');
    // We do not want to modify the existing elements
    const elems = clone(sourceElements);

    setIsUpdating(true);
    const orgId = project.organizationId
    const transformedElems = elems
    .filter(e => e.code !== undefined)
    .map((e, idx) => ({
      id: idx,
      unitId: e.unitId === 0 ? 1000 : e.unitId,
      code: e.code !== null ? e.code.split('.')[0] : '0',
      // name: '',
    }));
    const response = await Api().templates().search(transformedElems, orgId);

    if (response.results.length >= 0) {
      elems.forEach((e, idx) => {
        const result = response.results.find((r) => r.id === idx);
        if (result !== undefined) {
          const { templates } = result;
          const firstTemplate = templates.length > 0 && (
            templates.sort((a) => (a.projectType === projectType ? -1 : 1))
          )[0];
          e.templateOptions = templates;
          e.template = firstTemplate;
          e.templateId = firstTemplate !== false && firstTemplate.id;
          e.templateName = firstTemplate !== false && firstTemplate.name;
        }
      });
      setElements(elems);
    }
    setIsUpdating(false);
  };

  const elementsFromDataByHeader = useCallback(() => {
    const codeSourceField = mappings.find((m) => m.name === 'code').source;
    const groupMapping = mappings.find((m) => m.name === 'groupName');
    const groupSourceField = groupMapping !== undefined ? groupMapping.source : '';
    const expandedRawData = rawData // pick raw data
      .map((r, idx) => {
        // hasCode means that the row actually have some sort of value in code column
        // By default code is defined if it can be found in codes array (infraRYL)
        // let hasCode = codes.map((c) => c.name).includes(r[codeSourceField]);
        let hasCode = true;

        // However, if you disable code requirement,
        // it is enough to have just something that is not empty
        // if (!requireCode) {
        //   hasCode = r[codeSourceField] !== '' && r[codeSourceField] !== undefined;
        // }
        if (requireCode) {
          hasCode = r[codeSourceField] !== '' && r[codeSourceField] !== undefined;
        }

        // By default all rows are "normal" rows
        let isGroup = false;

        // However, if groups are enabled, we look for group field
        if (groupMapping.enabled) {
          isGroup = r[groupSourceField] !== '' && r[codeSourceField] !== null && r[groupSourceField] !== undefined && isAlmostEmptyRow(r);
        }
        return {
          hasCode,
          isGroup,
          isDescription: isAlmostEmptyRow(r) && !hasCode,
          description: null, // Will denote if the current row has description
          ...r,
          // parentGroupId: targetGroup.id,
          template: null,
          id: idx,
        };
      });

    // Populate group names
    if (expandedRawData.filter((r) => r.hasCode === true).length > (rawData.length * 0.20)) {
      // Most likely this is the code

      loglevel.info('mapping is', mappings);
      // Populate group data
      // Group data is not found inline. It is stateful data that changes based on some key value
      // Group rows are already in data.
      // Let's populate groups
      let currentGroup = '';
      expandedRawData.forEach((row, idx) => {
        if (row.isGroup) {
          currentGroup = row[groupSourceField];
        }
        if (row.hasCode) {
          expandedRawData[idx].groupName = (`${currentGroup}`).trim();
        }
      });
    }

    // Populate description to name
    const nameProp = mappings.filter((m) => m.enabled).find((m) => m.name === 'name').source;
    expandedRawData.forEach((row, idx, rows) => {
      if (row.isDescription && idx > 0) {
        if (row[nameProp] !== undefined) {
          rows[idx - 1].description = row[nameProp];
        }
      }
    });

    const rows = expandedRawData
      .filter((r) => !r.isGroup && !r.isDescription) // We get rid of groups and descriptions
      .filter((r) => r.hasCode) // All rows require code field
      .map((r) => {
        const unit = findUnit(r[mappings.filter((m) => m.enabled).find((m) => m.name === 'unitId').source]);
        return mappings.filter((m) => m.enabled).reduce((acc, cur) => {
          if (cur.name === 'unitId') {
            if (unit !== undefined) {
              acc.unit = unit.abbreviation;
              acc.unitId = unit.id;
            } else {
              acc.unit = '';
              acc.unitId = 0;
            }
          } else if (cur.name === 'groupName') {
            acc.groupName = r.groupName;
          } else if (cur.name === 'name') {
            acc.name = r[cur.source];
            if (r.description !== null) {
              acc.name += `, ${r.description}`;
            }
          } else {
            acc[cur.name] = r[cur.source];
            if (cur.type === 'string') {
              const val = r[cur.source];
              if (val !== undefined && val !== null) {
                acc[cur.name] = val.toString();
              }

              if (val === undefined) {
                acc[cur.name] = null;
              }
            }
            if (cur.type === 'number') {
              acc[cur.name] = !Number.isNaN(Number(r[cur.source])) ? Number(r[cur.source]) : '';
            }
          }
          acc.template = cur.template;
          acc.templateId = cur.templateId === undefined ? 0 : cur.templateId;
          acc.templateName = cur.templateName === undefined ? 0 : cur.templateName;
          acc.templateOptions = [];

          // acc.parentGroupId = targetGroup.id;
          acc.id = r.id;
          return acc;
        }, {});
      });
    return rows;
  }, [mappings, rawData, codes, requireCode, targetGroup.id, findUnit]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(elements);
    }
  }, [elements]);

  const toggleMappingByName = (mappingName) => {
    // setMappings(mappings.filter(m => m.name !== mappingName))
    const existing = mappings.find((m) => m.name === mappingName);
    existing.enabled = !existing.enabled;

    setMappings([...mappings]);
  };

  const checkElement = useCallback((e) => {
    let valid = true;
    let message = '';
    mappings.filter((m) => m.enabled).forEach((m) => {
      const value = e[m.name];
      if (m.type === 'string') {
        if (typeof value !== 'string' && value !== null) {
          valid = false;
          message = `${t(m.text)} ${t('is not string')}`;
        }
      } else if (m.type === 'unit') {
        if (Number.isNaN(Number(value)) || Number(value) <= 0) {
          valid = false;
          message = `${t(m.text)} ${t('is not valid unit')}`;
        }
      } else if (m.type === 'number') {
        if (Number.isNaN(Number(value))) {
          valid = false;
          message = `${t(m.text)} ${t('is not valid number')}`;
        }
        if (typeof value === 'number' && Number.isNaN(value)) {
          valid = false;
          message = `${t(m.text)} ${t('is not valid number')}`;
        }
      }
    });

    if (valid) {
      return false;
    }
    loglevel.debug(message);

    return true;
  }, [mappings]);
  const removeElement = (element) => {
    loglevel.info('Remove element');
    setElements(elements.filter((e) => e !== element));
  };

  const handleElementChange = (element) => (event) => {
    const { name } = event.currentTarget;
    const { value } = event.currentTarget;
    setElements((prev) => {
      const original = prev.find((elem) => elem.id === element.id);
      if (original !== undefined) {
        original[name] = value;
      }
      return prev;
    });
    updateTemplates(elements);
  };
  const getUnit = useCallback((unitId) => {
    if (Number(unitId) === 0) {
      return '';
    }
    return units.find((u) => u.id === Number(unitId)).abbreviation;
  }, [units]);

  useEffect(() => {
    if (headers.length > 0 && rawData !== undefined) {
      loglevel.info('updating elements...');
      const elems = elementsFromDataByHeader();
      if (elems !== undefined) {
        updateTemplates(elems);
      }
    }
  }, [mappings, requireCode, headers]);

  const handleTemplateSelect = (element) => (evt) => {
    const currentElement = element;
    const { value } = evt.currentTarget;
    // value is templateId
    const template = currentElement.templateOptions.find((tpl) => tpl.id === Number(value));

    if (template === undefined) {
      currentElement.template = undefined;
      currentElement.templateId = 0;
      currentElement.templateName = 0;
    } else {
      currentElement.template = template;
      currentElement.templateId = template.id;
      currentElement.templateName = template.name;
    }

    const updatedElements = elements.map((ele) => (
      ele.id === currentElement.id ? { ...currentElement } : { ...ele }
    ));

    setElements([...updatedElements]);
  };

  const resetTemplates = () => {
    setElements((prev) => prev.map((e) => ({
      ...e,
      template: undefined,
      templateId: 0,
      templateName: 0,
    })));
  };

  // Form validation
  useEffect(() => {
    const errors = elements.some((element) => checkElement(element));
    if (errors || elements.length === 0) {
      setValidImport(false);
    } else {
      setValidImport(true);
    }
  }, [elements]);

  const tooltipMainGroup = (
    <Tooltip id="tooltip-main-group">
      {t('import.tooltip-main-group')}
    </Tooltip>
  );
  const tooltipCodeRequired = (
    <Tooltip id="tooltip-code-required">
      {t('import.Remove rows without code')}
    </Tooltip>
  );
  const tooltipValidate = (
    <Tooltip id="tooltip-main-group">
      {t('import.tooltip-validate')}
    </Tooltip>
  );
  const tooltipRemoveMapping = (
    <Tooltip id="tooltip-remove-mapping">
      {t('import.tooltip-remove-mapping')}
    </Tooltip>
  );
  const tooltipManageHeaders = (
    <Tooltip id="tooltip-toggle-mapping">
      {t('import.Manage headers')}
    </Tooltip>
  );

  // Manage headers popover
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

   // object (organizationIdToLink) to map the organizationId values to their respective links.
   const organizationIdToLink = {
    1: FIsampleData,
    2: DKsampleData,
    3: UKsampleData,
    4: DEsampleData,
    6: MCsampleData,
  };

  const selectedLink = organizationIdToLink[project.organizationId];

  const hyperlink = selectedLink ? (
    <a href={selectedLink} target="_blank" download rel="noreferrer">
      {t('import.download-sample')}
    </a>
  ) : (
    <a href={UKsampleData} target="_blank" download rel="noreferrer">
      {t('No organisation ID')}
    </a>
  );

  return (
    <Form className="import">
      <Row>
        <Col>
          <h5>{t('import.Source file')}</h5>
          {!file ? (
            <>
              <Form.File
                id="custom-file"
                label={t('import.Import data file from your computer')}
                custom
                data-browse={t('import.Browse')}
                onChange={handleFile}
                accept=".xlsx, .xls"
              />
              <div className="text-center mb-3">
                <small>
                  {project.organizationId == 1 && t('import.import-file-guide')}
                  {' '}
                  {hyperlink}
                  .
                </small>
              </div>
            </>
          ) : null}
          {file !== undefined && file !== null && (
            <Alert variant="primary">
              <div className="import__file">
                <FiFile size="40" className="text-primary mr-2" />
                <div>
                  <strong>{`${file.name}`}</strong>
                  <div>
                    {reportType !== null && <Badge variant="primary" size="xl" className="mr-2">{reportType}</Badge>}
                    <small>{`${t('import.Number of rows')}: ${rawData.length}`}</small>
                  </div>
                </div>
              </div>
            </Alert>
          )}
        </Col>
      </Row>
      {/* {!importing && elements.length > 0 && groups.length > 1 && (
      <Row className="pb-3">
        <Col>
          <div className="d-flex align-items-center mb-2">
            <h5 className="mb-0">{t('import.Parent group')}</h5>
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltipMainGroup}>
              <Button variant="outline-link">
                <FiInfo />
                <span className="sr-only">{t('import.tooltip-main-group')}</span>
              </Button>
            </OverlayTrigger>
          </div>
          <DropDown size="sm" object={targetGroup} prop="id" options={groups.filter((g) => g.parentId === null)} optiontext="{code} {name}" optionvalue="id" onChange={(groupId) => (e) => { setTargetGroup((g) => g.id = groupId); }} />
        </Col>
      </Row>
      )} */}
      {(isUpdating || importing) && (
        <div className="loading">
          <Spinner variant="primary" animation="border" role="status" className="loading__spinner mr-4" size="lg">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {elements.length > 0 && (
        <>
          <Row className="pt-3">
            <Col>
              <h5 className="d-flex align-items-center">
                {t('import.Validate data')}
                <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={tooltipValidate}>
                  <Button variant="outline-link">
                    <FiInfo />
                    <span className="sr-only">{t('import.tooltip-main-group')}</span>
                  </Button>
                </OverlayTrigger>
              </h5>
            </Col>
            <Col md="auto" className="d-flex justify-content-end align-items-center">
              {file !== undefined && (
                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltipCodeRequired}>
                  <Form.Check
                    type="switch"
                    id="code-is-required"
                    label={t('import.Require code')}
                    checked={requireCode}
                    value={requireCode}
                    onChange={(e) => setRequireCode(e.currentTarget.checked)}
                  />
                </OverlayTrigger>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {mappings.filter((m) => m.enabled).map((m) => (
                      <th key={m.name}>
                        <Form.Group className="mb-1">
                          <Form.Label>
                            {t(m.text)}
                          </Form.Label>
                          <InputGroup>
                            <DropDown
                              disabled={!m.enabled}
                              object={m}
                              prop="source"
                              options={headers}
                              onChange={(m) => (e) => {
                                setMappings((cur) => {
                                  const idx = cur.findIndex((c) => c.name === m.name);
                                  cur[idx] = { ...m };
                                  return [...cur];
                                });
                              }}
                              optiontext="{key} - {example}"
                              optionvalue="key"
                            />
                            {m.selectable && (
                              <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltipRemoveMapping}>
                                <Button size="sm" variant="secondary" className="ml-2" onClick={() => toggleMappingByName(m.name)}>
                                  {m.enabled && <FaMinus />}
                                  <span className="sr-only">{t('import.tooltip-remove-mapping')}</span>
                                </Button>
                              </OverlayTrigger>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </th>
                    ))}
                    <th>
                      <div className="mb-2">{t('element.Template')}</div>
                      <Button className="mb-1" variant="outline-secondary" size="" style={{ width: '400px' }} onClick={() => resetTemplates()}>{t('import.Reset templates')}</Button>
                    </th>
                    <th className="text-center">
                      <Form.Label>
                      {t('import.Select headers')}
                    </Form.Label>
                      <br/>
                      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltipManageHeaders}>
                        <Button variant="secondary" onClick={handleClick}>
                          <FiFilter />
                          <span className="sr-only">{t('import.Manage headers')}</span>
                        </Button>
                      </OverlayTrigger>
                      <Overlay
                        show={show}
                        target={target}
                        placement="left"
                        containerPadding={20}
                        rootClose={true}
                        onHide={() => setShow(false)}
                      >
                        <Popover id="popover-contained">
                          <Popover.Title as="h3">{t('import.Select headers')}</Popover.Title>
                          <Popover.Content>
                            {mappings.map((m) => (
                              <Fragment key={m.name}>
                                <Form.Check
                                  id={m.name}
                                  type="checkbox"
                                  label={t(m.text)}
                                  onClick={() => toggleMappingByName(m.name)}
                                  defaultChecked={m.enabled}
                                  disabled={!m.selectable}
                                />
                              </Fragment>
                            ))}
                          </Popover.Content>
                        </Popover>
                      </Overlay>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {elements.length > 0 && elements.map((e) => (
                    <>
                      <tr
                        key={e.id}
                        onClick={() => { setEditRow(e); }}
                        style={{ background: checkElement(e) && '#c6341844' }}
                        className={showErrorRows && !checkElement(e) && 'd-none'}
                      >
                        {mappings.filter((m) => m.enabled).map((m) => (
                          <Fragment key={m.name}>
                            <td>
                              {editRow === e ? (
                                <>
                                  {m.widget === 'TextField' && <TextField object={e} prop={m.name} onChange={handleElementChange} />}
                                  {m.widget === 'CodeList'
                                    && <DropDown object={e} prop={m.name} options={codes} optionvalue="name" optiontext="name" onChange={handleElementChange} />}
                                  {m.widget === 'UnitList'
                                    && <DropDown object={e} prop={m.name} options={availableUnits} optionvalue="id" optiontext="abbreviation" onChange={handleElementChange} />}
                                </>
                              ) : (
                                <>
                                  {(m.type === 'string' || m.type === 'number') && e[m.name]}
                                  {(m.type === 'unit') && getUnit(e[m.name])}
                                </>
                              )}
                            </td>
                          </Fragment>
                        ))}
                            <td  style={{ width: '400px' }}>
                          {e.templateOptions.length > 0 ? (
                            <DropDown
                              object={e}
                              prop="templateId"
                              options={e.templateOptions.map((o) => ({ ...o, longname: `${o.code} - ${o.name} (${o.description ? o.description : ''}), ${t(`project.${Object.keys(projectTypes).find((k) => projectTypes[k] === o.projectType)}`)}` }))}
                              optionvalue="id"
                              optiontext="longname"
                              onChange={handleTemplateSelect}
                            />
                          ) : (
                            <small className="d-flex align-items-center text-muted mt-1">
                              <span>{t('import.No templates')}</span>
                            </small>
                          )}
                          {e.templateOptions.length > 1 && (
                            <small className="d-flex align-items-center text-info mt-1">
                              <FiInfo className="mr-1" />
                              <span>{`${t('import.Multiple options')} (${e.templateOptions.length})`}</span>
                            </small>
                          )}
                        </td>
                        <td>
                          <ButtonGroup>
                            <Button variant="primary-outline" size="sm" onClick={() => { setEditRow(e); }}><FaPen /></Button>
                            <Button variant="danger" size="sm" onClick={() => { removeElement(e); }}><FaTrash /></Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
});

export default ImportForm;
